
    <template>
      <section class="content element-doc">
        <h2>Switch 开关</h2>
<p>表示两种相互对立的状态间的切换，多用于触发「开/关」。</p>
<h3>基本用法</h3>
<demo-block>
        <div><p>绑定<code>v-model</code>到一个<code>Boolean</code>类型的变量。可以使用<code>active-color</code>属性与<code>inactive-color</code>属性来设置开关的背景色。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-switch v-model=&quot;value&quot; active-color=&quot;#13ce66&quot; inactive-color=&quot;#ff4949&quot;&gt;
&lt;/el-switch&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value = ref(true)
      return { value }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>文字描述</h3>
<demo-block>
        <div><p>使用<code>active-text</code>属性与<code>inactive-text</code>属性来设置开关的文字描述。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-switch v-model=&quot;value1&quot; active-text=&quot;按月付费&quot; inactive-text=&quot;按年付费&quot;&gt;
&lt;/el-switch&gt;
&lt;el-switch
  style=&quot;display: block&quot;
  v-model=&quot;value2&quot;
  active-color=&quot;#13ce66&quot;
  inactive-color=&quot;#ff4949&quot;
  active-text=&quot;按月付费&quot;
  inactive-text=&quot;按年付费&quot;
&gt;
&lt;/el-switch&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value1 = ref(true)
      const value2 = ref(true)
      return { value1, value2 }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>扩展的 value 类型</h3>
<demo-block>
        <div><p>设置<code>active-value</code>和<code>inactive-value</code>属性，接受<code>Boolean</code>, <code>String</code>或<code>Number</code>类型的值。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-tooltip :content=&quot;'Switch value: ' + value&quot; placement=&quot;top&quot;&gt;
  &lt;el-switch
    v-model=&quot;value&quot;
    active-color=&quot;#13ce66&quot;
    inactive-color=&quot;#ff4949&quot;
    active-value=&quot;100&quot;
    inactive-value=&quot;0&quot;
  &gt;
  &lt;/el-switch&gt;
&lt;/el-tooltip&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value = ref('100')
      return { value }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>禁用状态</h3>
<demo-block>
        <div><p>设置<code>disabled</code>属性，接受一个<code>Boolean</code>，设置<code>true</code>即可禁用。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-switch v-model=&quot;value1&quot; disabled&gt; &lt;/el-switch&gt;
&lt;el-switch v-model=&quot;value2&quot; disabled&gt; &lt;/el-switch&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value1 = ref(true)
      const value2 = ref(false)
      return { value1, value2 }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>绑定值</td>
<td>boolean / string / number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>width</td>
<td>switch 的宽度（像素）</td>
<td>number</td>
<td>—</td>
<td>40</td>
</tr>
<tr>
<td>active-icon-class</td>
<td>switch 打开时所显示图标的类名，设置此项会忽略 <code>active-text</code></td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>inactive-icon-class</td>
<td>switch 关闭时所显示图标的类名，设置此项会忽略 <code>inactive-text</code></td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>active-text</td>
<td>switch 打开时的文字描述</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>inactive-text</td>
<td>switch 关闭时的文字描述</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>active-value</td>
<td>switch 打开时的值</td>
<td>boolean / string / number</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>inactive-value</td>
<td>switch 关闭时的值</td>
<td>boolean / string / number</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>active-color</td>
<td>switch 打开时的背景色</td>
<td>string</td>
<td>—</td>
<td>#409EFF</td>
</tr>
<tr>
<td>inactive-color</td>
<td>switch 关闭时的背景色</td>
<td>string</td>
<td>—</td>
<td>#C0CCDA</td>
</tr>
<tr>
<td>name</td>
<td>switch 对应的 name 属性</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>validate-event</td>
<td>改变 switch 状态时是否触发表单的校验</td>
<td>boolean</td>
<td>-</td>
<td>true</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>switch 状态发生变化时的回调函数</td>
<td>新状态的值</td>
</tr>
</tbody>
</table>
<h3>Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>focus</td>
<td>使 Switch 获取焦点</td>
<td>-</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_switch = _resolveComponent("el-switch")

  return (_openBlock(), _createBlock(_component_el_switch, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": $event => (_ctx.value = $event),
    "active-color": "#13ce66",
    "inactive-color": "#ff4949"
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value = ref(true)
      return { value }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_switch = _resolveComponent("el-switch")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_switch, {
      modelValue: _ctx.value1,
      "onUpdate:modelValue": $event => (_ctx.value1 = $event),
      "active-text": "按月付费",
      "inactive-text": "按年付费"
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_switch, {
      style: {"display":"block"},
      modelValue: _ctx.value2,
      "onUpdate:modelValue": $event => (_ctx.value2 = $event),
      "active-color": "#13ce66",
      "inactive-color": "#ff4949",
      "active-text": "按月付费",
      "inactive-text": "按年付费"
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value1 = ref(true)
      const value2 = ref(true)
      return { value1, value2 }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: 'Switch value: ' + _ctx.value,
    placement: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_switch, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": $event => (_ctx.value = $event),
        "active-color": "#13ce66",
        "inactive-color": "#ff4949",
        "active-value": "100",
        "inactive-value": "0"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _: 1
  }, 8, ["content"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value = ref('100')
      return { value }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_switch = _resolveComponent("el-switch")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_switch, {
      modelValue: _ctx.value1,
      "onUpdate:modelValue": $event => (_ctx.value1 = $event),
      disabled: ""
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_switch, {
      modelValue: _ctx.value2,
      "onUpdate:modelValue": $event => (_ctx.value2 = $event),
      disabled: ""
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value1 = ref(true)
      const value2 = ref(false)
      return { value1, value2 }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  